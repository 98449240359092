<template>
  <div>
    <section class="white">
      <v-container>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                ŚWIADCZONE PRZEZ NAS USŁUGI
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                ENERGETYKA WAGON SP. Z O.O.
              </p>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <!-- <v-card-title>
                    SAFE INVESTMENTS FOR THE TIMES
                  </v-card-title> -->
                  <v-card-text>
                    Usługi są drugim, obok sprzedaży energii elektrycznej, polem naszej działalności. Ich realizacja odbywa się poprzez naszych pracowników w całym naszym otoczeniu. Dzięki bogatemu zapleczu technicznemu i wysoko wykwalifikowanej kadrze jesteśmy w stanie zapewnić najlepszą jakość wykonania, także w przypadku inwestycji. Posiadamy niezbędne uprawnienia, wiedzę i doświadczenie aby zrealizować niemal każde zadanie w zakresie:
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5 background-light-grey">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                USŁUGI ELEKTROENERGETYCZNE
              </p>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text>
                    <ul>
                      <li>naprawy, remonty i budowa instalacji elektrycznych budynków mieszkalnych, usługowych i produkcyjnych</li>
                      <li>budowa i remonty linii kablowych i napowietrznych nN i SN</li>
                      <li>wykonywanie przyłączy kablowych i napowietrznych do budynków mieszkalnych, usługowych i produkcyjnych</li>
                      <li>budowa stacji elektroenergetycznych</li>
                      <li>budowa oraz utrzymanie urządzeń oświetlenia zewnętrznego i wewnętrznego budynków, obiektów mieszkalnych, gospodarczych i przemysłowych</li>
                      <li>wykonywanie, eksploatacja i modernizacja oświetlenia ulicznego i terenów</li>
                      <li>pomiary eksploatacyjne ochrony przeciwporażeniowej urządzeń i instalacji elektrycznych</li>
                      <li>pomiary eksploatacyjne instalacji odgromowych</li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                USŁUGI REMONTOWE URZĄDZEŃ DOZOROWYCH
              </p>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text class="background-light-grey">
                    <ul>
                      <li>urządzenia dźwigowe</li>
                      <li>suwnice</li>
                      <li>żurawiki</li>
                      <li>podnośniki</li>
                      <li>zbiorniki ciśnieniowe</li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5 background-light-grey">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                POSIADANE UPRAWNIENIA
              </p>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text>
                    <ul>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped>
  .pure-border {
    border-left: 3px solid black;
  }
  .background-light-grey {
    background-color: #f1f1f1;
  }
</style>

<script>
  export default {
    data: () => ({
      highlights: [
        'Projekty i architektura',
        'Wykończenia wnętrz',
        'Inwestor zastępczy',
        'Wykonawstwo i nadzory',
        'Marketing',
        'Sprzedaż'
      ],
      experiences: [
        ['mdi-vuejs', '75', 'Vue'],
        ['mdi-vuetify', '100', 'Vuetify'],
        ['mdi-aws', '67', 'AWS'],
        ['mdi-server-security', '95', 'Security']
      ]
    })
  }
</script>
